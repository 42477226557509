/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy, useEffect, useState } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Logout, AuthPage } from "./pages/account/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import api from "../redux/api";
import actions from "../redux/actions";
import InstallPodcastPage from "./pages/products/InstallPodcastPage";
import { ErrorBoundary } from "./helpers";
import AuthorPage from "./pages/author";
import PublicPage from "./pages/products/sales-page/PublicPage";
import EmbeddedPlayer from "./pages/products/sales-page/EmbeddedPlayer";
import AdminRouter from "./pages/admin/AdminRouter";
import RssImportResultPage from "./pages/generic/RssImportResultPage";
import BasePage from "./BasePage";
import Validation from "./pages/app/Validation";
import ResetPassword from "./pages/app/ResetPassword";
import SocialLoginResults from "./pages/app/SocialLoginResults";
import AppInstall from "./pages/app/AppInstall";
import LaunchApp from "./pages/app/LaunchApp";
import AIPodcastCreator from "./pages/ai-experimental/AIPodcastCreator";
import GHLSuccess from "./pages/account/UserProfile/GHLSuccess";
import OptOutSuccess from "./pages/generic/OptOutSuccess";

const RoutesSwitch = () => {
	const location = useLocation(),
		[routes, setRoutes] = useState(null);

	const { isAuthorized, user } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.authToken != null && auth.user != null,
			user: auth.user
		}),
		shallowEqual
	);

	async function loadUserRoutes() {
		let res = await api.user.getRoutes(window.location.hostname);

		if(!res || !res.success || !res.data.length)
			return (window.location.href = process.env.REACT_APP_LOCAL_URL + "error/error-v3");

		setRoutes(res.data);
	}

	useEffect(() => {
		if(window.location.hostname != process.env.REACT_APP_HOST)
			loadUserRoutes();
	}, []);

	//Sales page
	if(window.location.hostname != process.env.REACT_APP_HOST)
		return routes
			? (
				<Switch>
					{routes.filter(route => !!route.path).map((route, i) => {
						const Component = {
							"profile": AuthorPage,
							"product": PublicPage
						}[route.kind];
						return <Route key={i} exact path={route.path} render={props => <Component {...props} productId={route.productId} userId={route.userId} routes={routes} />} />;
					})}
					<Route render={props => (window.location.href = process.env.REACT_APP_LOCAL_URL + "error/error-v3")} />
				</Switch>
			)
			: <></>;

	return (
		<Switch>
			<Route exact path="/ghl-success" component={GHLSuccess} />
			<Route exact path="/install/:productid/:privatekey" component={InstallPodcastPage} />
			<Route exact path="/rss-import-:status" component={RssImportResultPage} />
			<Route exact path="/optout" component={OptOutSuccess} />

			<Route exact path="/validation/:type/:status" component={Validation} />
			<Route exact path="/app-social-login/:source/:status/:token?" component={SocialLoginResults} />
			<Route exact path="/reset-app-password/:code" component={ResetPassword} />
			<Route exact path="/app-install/:product/:key" component={AppInstall} />
			<Route exact path="/launch-app" component={LaunchApp} />

			<Route path="/pro/:uri" component={AuthorPage} />
			<Route path="/channel/:uri" component={PublicPage} />
			<Route exact path="/embed/:id/:episodes" component={EmbeddedPlayer} />
			<Route exact path="/embed/:id" component={EmbeddedPlayer} />

			<Route path="/admin" component={AdminRouter} />

			<Route path="/auth" component={AuthPage} />

			{!isAuthorized ? <Redirect to={{ pathname: "/auth/login", hash: location.hash, search: location.search }} /> : ""}

			{user && user.pendingSignup && <Redirect to={"/auth/select-plan"} />}

			<Route path="/error" component={ErrorsPage} />
			<Route path="/logout" component={Logout} />

			<Route path="/ai-podcast-creator/:id" component={AIPodcastCreator} />
			<Route path="/ai-podcast-creator" component={AIPodcastCreator} />

			<Route path="" component={BasePage} />
		</Switch>
	);
};

export function Routes() {
	const location = useLocation();

	useEffect(() => {
		api.pub.event("PageView");
	}, [location]);

	return process.env.NODE_ENV === "production" ? (
		<ErrorBoundary>
			<RoutesSwitch />
		</ErrorBoundary>
	) : (
		<RoutesSwitch />
	);
}